<template>
    <div class="container-fluid mt-3 mb-3">
        <div v-if="!selectedAccount" class="d-flex flex-column align-items-center justify-content-center">
            <h4 class="my-5">{{ $t('selectAccount')}}</h4>
        </div>
        <div v-if="selectedAccount">
            <!-- Filters -->
            <div class="card">
                <div class="card-body d-flex flex-column">
                    <div class="mt-3 d-inline-flex">
                        <p class="me-2 mb-0">{{$t('account')}}:</p>
                        <p class="m-0">{{ `${selectedAccount.number} (${selectedAccount.name})` }}</p>
                    </div>
                    <div class="mt-3 d-inline-flex align-items-baseline">
                        <el-date-picker
                            format="yyyy-MM-dd HH:mm:ss"
                            value-format="yyyy-MM-ddTHH:mm:ss"
                            @change="fetchTrades"
                            size="small"
                            style="max-width: 150px"
                            v-model="startOpenTime"
                            type="date"
                            :placeholder="$t('from')">
                        </el-date-picker>
                        <span class="mx-2"> - </span>
                        <el-date-picker
                            format="yyyy-MM-dd HH:mm:ss"
                            value-format="yyyy-MM-ddTHH:mm:ss"
                            @change="fetchTrades"
                            size="small"
                            style="max-width: 150px"
                            v-model="endOpenTime"
                            type="date"
                            :placeholder="$t('to')">
                        </el-date-picker>
                    </div>

                    <div class="mt-3 d-inline-flex align-items-baseline">
                        <p class="me-2">{{$t('symbols')}}: </p>
                        <el-select
                            @change="fetchTrades"
                            size="small"
                            multiple
                            collapse-tags
                            v-model="selectedSymbols"
                            :placeholder="$t('selectSymbols')">
                            <el-option
                                v-for="symbol in symbols"
                                :key="`symbol-${symbol}`"
                                :label="symbol"
                                :value="symbol">
                            </el-option>
                        </el-select>
                    </div>

                    <div class="mt-3 d-inline-flex align-items-baseline">
                        <p class="me-2">{{ $t('types') }}: </p>
                        <el-select
                            @change="fetchTrades"
                            size="small"
                            multiple
                            collapse-tags
                            v-model="selectedTypes"
                            :placeholder="$t('selectTypes')">
                            <el-option
                                v-for="type in types"
                                :key="`type-${type.value}`"
                                :label="type.label"
                                :value="type.value">
                            </el-option>
                        </el-select>
                    </div>
                </div>
            </div>

            <div class="card mt-4 overflow-scroll">
                <table class="table responsive">
                    <thead>
                    <tr>
                        <th scope="col">{{$t('ticket')}}</th>
                        <th scope="col">{{$t('symbol')}}</th>
                        <th scope="col">{{$t('type')}}</th>
                        <th scope="col">{{$t('lots')}}</th>
                        <th scope="col">{{$t('openTime')}}</th>
                        <th scope="col">{{$t('openPrice')}}</th>
                        <th scope="col">{{$t('closeTime')}}</th>
                        <th scope="col">{{$t('closePrice')}}</th>
                        <th scope="col">{{$t('profit')}}</th>
                    </tr>
                    </thead>
                    <tbody>
                    <tr v-for="(trade, tradeIndex) in trades" :key="`trade-${tradeIndex}`">
                        <td>{{ trade.ticket }}</td>
                        <td>{{ trade.symbol }}</td>
                        <td>{{ tradeTypeToName(trade.type) }}</td>
                        <td>{{ trade.lots }}</td>
                        <td>{{ dateTimeToString(trade.open_time) }}</td>
                        <td>{{ trade.open_price }}</td>
                        <td>{{ dateTimeToString(trade.close_time) }}</td>
                        <td>{{ trade.close_price }}</td>
                        <td>{{ Number(trade.profit + trade.swap + trade.commission).toFixed(2) }}</td>
                    </tr>
                    <tr v-if="!trades.length">
                        <td colspan="9" class="text-center text-muted">{{$t('noPositions')}}</td>
                    </tr>
                    </tbody>
                </table>

                <table class="ms-auto table responsive" style="width: auto">
                    <tbody>
                    <tr>
                        <th>{{$t('openTrades')}}:</th>
                        <th>{{ trades.length }}</th>
                    </tr>
                    <tr>
                        <th>{{ $t('total') }}:</th>
                        <th>{{ trades.map(trade => trade.profit + trade.swap + trade.commission).reduce((acc, val) => acc + val, 0).toFixed(2) }}</th>
                    </tr>
                    </tbody>
                </table>
            </div>
        </div>
    </div>
</template>

<script>
import axios from '@/plugins/axios'
import {DateTime} from 'luxon'
import {tradeTypeToName, tradeTypes, dateTimeToString } from '@/common/helpers'

export default {
    name: 'TradesLive',
    i18n: {
        messages: {
            en: {
                selectAccount: 'Select account',
                account: 'Account',
                from: 'From',
                to: 'To',
                symbols: 'Symbols',
                selectSymbols: 'Select symbols',
                types: 'Types',
                selectTypes: 'Select types',

                ticket: 'Ticket',
                symbol: 'Symbol',
                type: 'Type',
                lots: 'Lots',
                openTime: 'Open time (GMT)',
                openPrice: 'Open price',
                closeTime: 'Close time (GMT)',
                closePrice: 'Close price',
                profit: 'Profit',

                noPositions: 'No positions found',

                openTrades: 'OPEN TRADES',
                total: 'TOTAL'
            },
            ru: {
                selectAccount: 'Выберите счет',
                account: 'Счет',
                from: 'От',
                to: 'До',
                symbols: 'Пары',
                selectSymbols: 'Выберите пары',
                types: 'Типы',
                selectTypes: 'Выберите типы',

                ticket: 'Тикет',
                symbol: 'Пара',
                type: 'Тип',
                lots: 'Лоты',
                openTime: 'Время открытия (GMT)',
                openPrice: 'Цена открытия',
                closeTime: 'Время закрытия (GMT)',
                closePrice: 'Цена закрытия',
                profit: 'Прибыль',

                noPositions: 'Позиции не найдены',

                openTrades: 'ОТКРЫТЫЕ ПОЗИЦИИ',
                total: 'ИТОГО'
            }
        }
    },
    data: function () {
        return {
            types: [...tradeTypes],
            symbols: [],
            trades: [],
            startOpenTime: DateTime.utc().toFormat("yyyy'-'LL'-'dd'") + 'T00:00:00',
            endOpenTime:DateTime.utc().toFormat("yyyy'-'LL'-'dd'") + 'T23:59:59',
            selectedSymbols: [],
            selectedTypes: [0,1,2,3,4,5,6],
            isLoading: false,
            liveTradesTimer: null,
        }
    },
    computed: {
        selectedAccount: function () {
            return this.$store.getters.ADMIN_SELECTED_ACCOUNT
        }
    },
    methods: {
        dateTimeToString,
        tradeTypeToName,
        fetchTrades: function () {
            if(!this.selectedSymbols.length || !this.selectedTypes) {
                this.trades = []
                return
            }
            this.isLoading = true
            axios.get(`/admin/trades`, {
                params: {
                    results: 0,
                    account_ids: this.selectedAccount.account_id,
                    symbols: this.selectedSymbols.join(','),
                    types: this.selectedTypes.join(','),
                    start_open_time: this.startOpenTime,
                    end_open_time: this.endOpenTime,
                }
            })
                .then(response => {
                    this.trades = response.data
                })
                .finally(() => {
                    this.isLoading = false
                })
        },
        fetchTradesSymbols: function () {
            axios.get(`/admin/trades-symbols`, {
                params: {
                    account_ids: this.selectedAccount.account_id,
                }
            })
                .then(response => {
                    this.symbols = response.data
                    if(this.selectedSymbols) {
                        this.selectedSymbols = [...this.symbols]
                    }
                })
        }
    },
    mounted() {
        if(this.selectedAccount) {
            this.fetchTradesSymbols()
            this.liveTradesTimer = setInterval(this.fetchTrades, 2000)
        }
    },
    beforeDestroy() {
        clearInterval(this.liveTradesTimer)
    }
}
</script>

<style scoped>
.table > :not(:last-child) > :last-child > * {
    border-bottom-color: inherit;
}
</style>
